import React, { Component } from 'react';
import classnames from 'classnames';

import Base from '../Base';
import Links from '../Links';

import Form from 'components/Util/Inputs/Form';
import InputL from 'components/Util/Inputs/InputL';
import CheckboxL from 'components/Util/Inputs/CheckboxL';
import Button from 'components/Util/Buttons/LightBackground/LargePrimarySquareButton';

import Google from 'components/Util/Buttons/SSO/Google';
import Microsoft from 'components/Util/Buttons/SSO/Microsoft';

import Store from 'models/Form';

import styles from 'styles/Marketing/Organizations/Sessions/New';

export default class New extends Component {
  store = new Store;

  onSuccess = () => {
    window.location = '/home';
  }

  render() {
    return (
      <Base>
        <h3 className={classnames(styles.h3, 'text-center')}>Sign In</h3>
        <Form method='post' action='/organizations/sign_in' onSuccess={this.onSuccess} store={this.store}>
          <InputL className='mb-2 w-100' name='organization_account[email]' placeholder='Email*' store={this.store} />
          <InputL className='mb-2 w-100' name='organization_account[password]' placeholder='Password*' type='password' store={this.store} />
          <div className='row mb-2'>
            <div className='col-6'>
              <CheckboxL defaultValue={true} name='organization_account[remember_me]' store={this.store}>
                Remember Me
              </CheckboxL>
            </div>
            <div className='col-6 text-end'>
              <a href='/organizations/password/new' className={classnames(styles.p2, styles.ColorPrimaryPurple, 'fw-bold')}>Forgot your password?</a>
            </div>
          </div>

          <Button className='w-100 mb-3'>Sign In</Button>
        </Form>

        <div className={classnames(styles.Divider, 'mb-3')}>
          <div />
          <p className={classnames(styles.p2, 'mb-0')}>OR</p>
        </div>

        <div className='text-center'>
          <Google href='/organizations/sso/oauth2/google' className='mb-2' />

          <Links links={['sign_up', 'confirmation']} />
        </div>
      </Base>
    );
  }
}
